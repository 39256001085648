import React, { FC, useCallback, useEffect } from 'react'
import { Typography, Button, Divider } from '@mui/material'

import { FormType } from '@norstella/nxp-sso-web/utils/Forms'
import { LoadingIndicator } from '@norstella/nxp-sso-web/components/LoadingIndicator'
import ApplicationCard from '@norstella/nxp-sso-web/components/ApplicationCard'

import { useAppContext } from '@norstella/nxp-sso-web/components/AppContextProvider'
import viewStyles from '@norstella/nxp-sso-web/components/views/FederatedSignupView.module.scss'
import { useBranding } from '@norstella/nxp-sso-web/hooks/useBranding'
import { IProfileData, RegistrationMode } from '@norstella/nxp-sso-core/interfaces/sso-api'
import { useNewEntitlementProviderIds } from '@norstella/nxp-sso-web/hooks/useNewEntitlementProviderIds'

export interface IEnsureUserRegistrationViewProps {
  onSubmitHandler: (profileData: IProfileData, newEntitlementProviderIds: string[], registrationMode: RegistrationMode) => Promise<void>,
  error?: Error,
}

const RenderLink = ({ href, text }: { href: string, text: string }) => (
  <>
    <span>&nbsp;</span>
    <a
      href={href}
      className="no-wrap"
      target="_blank"
      rel="noopener nofollow noreferrer"
    >
      <span className="no-wrap">{text}</span>
    </a>
  </>
)

const RenderEmail = ({ email, text }: { email: string, text: string }) => (
  <>
    <span>&nbsp;</span>
    <a
      href={`mailto:${email}`}
    >
      <span className="no-wrap">{text}</span>
    </a>
  </>
)

// eslint-disable-next-line complexity
export const EnsureUserRegistrationView: FC<IEnsureUserRegistrationViewProps> = ({ onSubmitHandler, error }) => {
  const [forms] = useBranding()
  const { header, description, footer } = forms[FormType.federatedSignup]
  const { profileData } = useAppContext()
  const newEntitlementProviderIds = useNewEntitlementProviderIds()

  const submitCallback = useCallback(() => {
    (async () => await onSubmitHandler(profileData, newEntitlementProviderIds, RegistrationMode.ENTITLEMENTS))()
  }, [profileData, newEntitlementProviderIds, onSubmitHandler])

  useEffect(() => {
    submitCallback()
  }, [submitCallback])

  const hasFooterLink = footer?.linkUrl && footer?.linkText
  const hasFooterEmail = footer?.emailAddress && footer?.emailText
  const shouldRenderHelpSection = error && (hasFooterLink || hasFooterEmail)

  return (
    <ApplicationCard header={header} description={description}>
      {!error && <LoadingIndicator/>}

      {shouldRenderHelpSection && (
        <>
          <Divider/>
          <Typography variant="h2" component="h2">
            Need help?
          </Typography>
          <Typography component="p">
            {footer?.text}
            {hasFooterLink && <RenderLink href={footer.linkUrl ?? ''} text={footer.linkText ?? ''}/>}
            {hasFooterEmail && <RenderEmail email={footer.emailAddress ?? ''} text={footer.emailText ?? ''}/>}
          </Typography>
        </>
      )}

      {error && (
        <div className={viewStyles.buttonSection}>
          <Button
            data-testid="update-button"
            type="submit"
            variant="contained"
            onClick={submitCallback}
            color="primary"
          >
            Try again
          </Button>
        </div>
      )}
    </ApplicationCard>
  )
}
