import { Navigate, useSearchParams } from 'react-router-dom'
import ApplicationCard from '@norstella/nxp-sso-web/components/ApplicationCard'
import { Typography } from '@mui/material'
import { useBranding } from '@norstella/nxp-sso-web/hooks/useBranding'
import { useAppContext } from '@norstella/nxp-sso-web/components/AppContextProvider'

export const VerifyEmailCallback = () => {
  const [searchParams] = useSearchParams()
  const [forms] = useBranding()
  const { user } = useAppContext()

  const isVerificationFailed = searchParams.get('success') === 'false'
  const isVerificationSuccess = !isVerificationFailed
  const searchParamsAsString = searchParams.toString()

  if (isVerificationSuccess) {
    return <Navigate to={`/signup?${searchParamsAsString}`}/>
  }

  if (user) {
    return <Navigate to={`/verify?${searchParamsAsString}`}/>
  }

  const message = searchParams.get('message')
  const isLinkExpired = message === 'Access expired.'

  let reason = message ?? 'Error'

  if (isLinkExpired) {
    reason = 'This link is expired for security reasons.'
  }

  const headerMetaError = forms.verifyEmailError

  return (
    <>
      <ApplicationCard header={headerMetaError.header} description={headerMetaError.description}>
        <Typography component="p">
          {reason}
        </Typography>
      </ApplicationCard>
    </>
  )
}
