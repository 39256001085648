import React, { FC, Fragment } from 'react'

import { Divider, Typography } from '@mui/material'
import styles from '@norstella/nxp-sso-web/components/views/ErrorPageView.module.scss'
import ApplicationCard from '@norstella/nxp-sso-web/components/ApplicationCard'
import { IHeaderElement, IFooterElement } from '@norstella/nxp-sso-web/utils/Forms'

export interface IErrorPageView {
  error?: string,
  errorDescription?: string,
  header: IHeaderElement,
  footer?: IFooterElement,
}

// eslint-disable-next-line complexity
const getFooterElement = (footer?: IFooterElement) => (footer && footer.text ?
  <Fragment>
    <Divider></Divider>
    <h2>Need help?</h2>
    <Typography component="p">
      {footer?.text}

      {footer && footer.linkUrl && footer.linkText && (
        <>
          <span>&nbsp;</span>
          <a
            href={footer.linkUrl}
            target="_blank"
            rel="noopener nofollow noreferrer"
          >
            <span className="no-wrap">{footer.linkText}</span>
          </a>
        </>
      )}

      {footer && footer.emailAddress && footer.emailText && (
        <>
          <span>&nbsp;</span>
          <a
            href={`mailto:${footer.emailAddress}`}
          >
            <span className="no-wrap">{footer.emailText}</span>
          </a>
        </>
      )}
    </Typography>
  </Fragment>
  : null)

export const ErrorPageView: FC<IErrorPageView> = (
  {
    error,
    errorDescription,
    header,
    footer,
  }) => {
  const footerEl = getFooterElement(footer)

  return <ApplicationCard className={styles.errorCard} header={header}>
    {
      error &&
        <Typography className={styles.errorName} component="p">
          {error}
        </Typography>
    }
    {
      errorDescription &&
        <Typography className={styles.errorDesc} component="p">
          {errorDescription}
        </Typography>
    }
    {footerEl}
  </ApplicationCard>
}
